$(function() {  

	$(document).on('click', '#navLoginButton', function(event){
		$("#loginModal").modal("show");
		$(".modal-manual").hide();
		$("#loginContent").show();
	});

	$(document).on('click', '#navSignupButton', function(event){

		$("#loginModal").modal("show");
		$(".modal-manual").hide();
		$("#signupContent").show();

	});

	$(document).on('click', '#signupButton', function(event){
		$(".modal-manual").hide();
		$("#signupContent").show();
	});

	$(document).on('click', '#loginButton', function(event){
		$(".modal-manual").hide();
		$("#loginContent").show();
	});

	$(document).on('click', '#forgotPasswordButton', function(event){
		$(".modal-manual").hide();
		$("#forgotPasswordContent").show();
	});

	$(document).on('click', '#signupOtpSubmit', function(event){
		$(".modal-manual").hide();
		$("#signupOtpContent").show();
	});

	$(document).on('click', '#signupOtpVerifySubmit', function(event){
		$(".modal-manual").hide();
		$("#registrationTypeContent").show();
	});

	$(document).on('click', '#resetPasswordSubmit', function(event){
		$(".modal-manual").hide();
		$("#resetPasswordOtpContent").show();
	});

	$(document).on('click', '#resetPasswordOtpVerifySubmit', function(event){
		window.location = "reset-password.html";
	});

});


function initMap() {
	var map;
	map = new google.maps.Map(document.getElementById('map'), {
		  center: {lat: -34.397, lng: 150.644},
		  zoom: 8
	});
}

	/*		

	      	function video(){
				var x = document.getElementById("inclinic");
				var y = document.getElementById("video");
				var z = document.getElementById("selection");
	        	x.style.left= "-600px";
	        	y.style.left= "0px";
	        	z.style.left= "231px";
	      	}
	       	function inclinic(){
				var x = document.getElementById("inclinic");
				var y = document.getElementById("video");
				var z = document.getElementById("selection");
	        	x.style.left= "0px";
	        	y.style.left= "700px";
	        	z.style.left= "0px";
	      	}*/